import {useRef, useState} from "react";

const DragAndDrop = ({onFileUpload})=>{
    const refFileUpload = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleOnClick = (e)=>{
        e.preventDefault();
        setIsDragging(false);
        // e.stopPropagation();
        refFileUpload.current.click();
        return false;
    };

    const handleFileUpload = (e)=>{
        e.stopPropagation();
        setIsDragging(false);
        const file = e.target.files[0];
        if(!file) {
            return false;
        }
        onFileUpload(file);
        // refFileUpload.value = '';
        return false;
    };

    // const handleDragStart = (e)=>{
    //     console.log('start', {e})
    // };
    const handleDragEnter = (e)=>{
        console.log('enter', {e})
        setIsDragging(true);
    };
    // const handleDragEnd = (e)=>{
    //     console.log('end', {e})
    // };
    const handleDragLeave = (e)=>{
        console.log('leave', {e})
        setIsDragging(false);
    };
    const handleDrag = (e)=>{
        console.log('drag', {e});
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        return false;
    };

    return (
        <div
            className="flex justify-center items-center w-full"
            style={{
                position: 'relative',
            }}
            draggable
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrag={handleDrag}
            // onClick={handleOnClick}
        >
            <label
                htmlFor="dropzone-file"
                className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                style={{
                    // pointerEvents: 'none',
                    transition: 'all 200ms',
                    // display:'block',
                    backgroundColor: '#eee',
                    borderStyle: isDragging ? 'solid' : 'dashed',
                    borderImageSource: isDragging ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' %3E%3Cstyle%3E@keyframes dash%7B0%25%7Bstroke-dashoffset:0%7D100%25%7Bstroke-dashoffset:15px%7D%3C/style%3E%3Cpolygon points='0,0 0,15 15,15 15,0' stroke-width='0.5' stroke-dasharray='1.0 0.5' style='animation:dash 2000ms infinite linear' stroke='%23d1d5db' fill='transparent'/%3E%3C/svg%3E")` : 'none',
                    borderImageSlice: 5,
                    borderWidth: '2px',
                    borderImageRepeat: 'round',
                    // width: '1000px',
                    // height: '500px',
                    // position:'relative',
                }}
            >
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Video, Photo, Any Media</p>
                </div>
            </label>
            <input
                id="dropzone-file"
                type="file"
                // className="hidden"
                ref={refFileUpload}
                onChange={handleFileUpload}
                style={{
                    top: -100,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    position: 'absolute',
                    cursor: 'pointer',
                }}
            />
        </div>
    );


    // return (
    //     <button
    //         draggable
    //         type="button"
    //         className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    //         onClick={handleOnClick}
    //         onDragStart={handleDragStart}
    //         onDragEnter={handleDragEnter}
    //         onDragLeave={handleDragLeave}
    //         onDragEnd={handleDragEnd}
    //         onDrag={handleDrag}
    //     >
    //         <div style={{pointerEvents: 'none'}}>
    //             <svg
    //                 className="mx-auto h-12 w-12 text-gray-400"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 stroke="currentColor"
    //                 fill="none"
    //                 viewBox="0 0 48 48"
    //                 aria-hidden="true"
    //             >
    //                 <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth={2}
    //                     d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
    //                 />
    //             </svg>
    //             <span className="mt-2 block text-sm font-medium text-gray-900">Step 1: Drag and Drop your bait image.</span>
    //         </div>
    //         <input
    //             type="file"
    //             ref={refFileUpload}
    //             onChange={handleFileUpload}
    //         />
    //     </button>
    // );
};
export default DragAndDrop;