import './App.css';

import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg';

import DragAndDrop from './DragAndDrop';
import {useRef, useState} from "react";

const urlToImageUrl = (url)=>{
    let out;
    url = url.replace(/^\s*(?:https?:)?(?:\/\/)?(?:www\.)?/, '');

    switch(true) {
        case /youtube|youtu\.be/.test(url): {
            const regex = /^(?:youtube\.com\/(?:watch\?v=|(?:embed|shorts|v|watch)\/)|youtu\.be\/)([a-z0-9_-]{11})/i;
            url.replace(regex, (z, id) => {
                out = `https://i.ytimg.com/vi/${id}/frame0.jpg`;
            });
            return out;
        }
        case /imgur/.test(url): {
            const regex = /^(?:i\.)?imgur\.com\/(?!gallery)([a-z0-9_]{3,})/i;
            url.replace(regex, (z, id)=>{
                out = `https://i.imgur.com/${id}.png`;
            });
            return out;
        }
        default:
            return url;
    }
}

const getDar = (width, height)=>{
    // lmao
    return `${width}/${height}`;
}
const getProxiedUrl = (url)=>{
    return `https://api.allorigins.win/raw?url=${url}`;
}

const replaceExtension = (filename, newExtension) => {
    let newFilename = filename.replace(/\.([a-z0-9_]{1,6})$/i, '') + `.${newExtension}`;
    if(filename === newFilename) {
        newFilename = newFilename.replace(new RegExp(newExtension, 'i'), ` (1).${newExtension}`);
    }
    return newFilename;
};

function App() {

    // avi=BROKEN
    // mkv=2.0
    // mov=2.5
    // mp4=4.0
    // webm=0.2
    // wmv=3.0
    const IN_VIDEO_EXTENSION = 'mp4';
    const OUT_VIDEO_EXTENSION = 'mp4';

    // const refVideo = useRef(null);
    // const refImg = useRef(null);

    const DEFAULT_FILENAME = `foolish.${OUT_VIDEO_EXTENSION}`;

    const [loadingData, setLoadingData] = useState({
        text: 'Waiting for your image/video drag and drop',
        step: 0,
    });

    let step = 0;

    const [vidBlobUrl, setVidBlobUrl] = useState(null); //'fools.mp4');
    const [vidPercent, setVidPercent] = useState(0);
    const [filename, setFilename] = useState(DEFAULT_FILENAME);

    const anticipations = [
        'I can\'t wait!',
        'I\'m excited!',
        'This is exciting!',
        'The suspense is killing me!',
        // '',
    ];

    const MIN_PERCENT = 1;

    const logger = (...args)=>{
        console.log({args})
    }

    const ffmpeg = createFFmpeg({
        // log: true,
        // logger: ({type, message}) => console.log({type, message}),
        progress: (progress)=>{
            console.log({progress, loadingData, step});

            if(step === 3) {
                const percent = Math.floor((Math.max(0, progress.time || 0) / 18.822) * 100);
                setVidPercent(progress.ratio === 1 ? 100 : Math.max(percent, MIN_PERCENT));
            }
        },
    });

    const loadFfmpeg = async()=>{
        if(!ffmpeg.isLoaded()) {
            await ffmpeg.load();
            ffmpeg.setLogger(logger);
            ffmpeg.setLogging(true);
        }
    }

    const getFirstFrameOfFile = async(originalFile)=>{

        await loadFfmpeg();

        ffmpeg.FS('writeFile', 'originalFile', await fetchFile(originalFile));
        await ffmpeg.run('-i', 'originalFile', '-vf', 'select=eq(n\\,0)', '-q:v', '3', 'frame.png');

        const data = ffmpeg.FS('readFile', 'frame.png');
        const fileInputFrame = new Blob([data.buffer], {type: 'image/png'});
        const bitmap = await createImageBitmap(fileInputFrame);

        // also make sure width and height are divisible by 2

        const width = Math.max(Math.floor(bitmap.width / 2) * 2, 256);
        const height = Math.max(Math.floor(bitmap.height / 2) * 2, 256);

        return {
            fileInputFrame,
            width,
            height,
        };
    };







    const getRandomFromArr = (arr)=>{
        const index = Math.floor(Math.random() * arr.length);
        return arr[index];
    };

    const handleUrl = async(url)=>{
        const imageUrl = getProxiedUrl(urlToImageUrl(url));
        await handleOnFileUpload(imageUrl);
    };

    const handleOnFileUpload = async(originalFile)=>{

        try {

            setVidPercent(0);

            if (vidBlobUrl) {
                URL.revokeObjectURL(vidBlobUrl);
            }
            setVidBlobUrl(null);

            setFilename(replaceExtension(originalFile.name || DEFAULT_FILENAME, OUT_VIDEO_EXTENSION));

            await setLoadingData({
                step: 1,
                text: `Loading tools`,
                anticipation: getRandomFromArr(anticipations),
            });
            step = 1;

            await loadFfmpeg();

            setLoadingData({
                step: 2,
                text: `Extracting first frame`,
                anticipation: getRandomFromArr(anticipations),
            });
            step = 2;


            const {fileInputFrame, width, height} = await getFirstFrameOfFile(originalFile);

            const blobUrlImg = URL.createObjectURL(fileInputFrame);

            // refImg.current.setAttribute('src', blobUrlImg);

            ffmpeg.FS('writeFile', 'fileInputFrame.png', await fetchFile(fileInputFrame));

            ffmpeg.FS('writeFile', `foolsVideo.${IN_VIDEO_EXTENSION}`, await fetchFile(`${document.location.protocol}//${document.location.host}/fools.${IN_VIDEO_EXTENSION}`))
            ffmpeg.FS('writeFile', 'font.ttf', await fetchFile(`${document.location.protocol}//${document.location.host}/verdana.ttf`));

            const framerate = 24;
            const oneFrameMs = Math.ceil(1000 / framerate);

            const dar = getDar(width, height);

            setLoadingData({
                step: 3,
                text: `Generating ${width}x${height} video`,
                anticipation: getRandomFromArr(anticipations),
            });
            setVidPercent(MIN_PERCENT);
            step = 3;

            const outFilename = `out.${OUT_VIDEO_EXTENSION}`;

            // TODO: make bg of uploaded 0=image #2f3136 ?
            const args = [
                '-loop', '1',
                '-framerate', `${framerate}`,
                '-t', `${oneFrameMs}ms`,
                '-i', 'fileInputFrame.png', //0=image
                '-f', 'lavfi',
                '-t', `${oneFrameMs}ms`,
                '-i', 'anullsrc',           //1=null
                '-i', `foolsVideo.${IN_VIDEO_EXTENSION}`,    //2=vid
                '-filter_complex',
                [
                    `[2:v]setdar=${dar},scale=${width}:${height},drawtext=fontfile=font.ttf:text=aprilfools.io:fontcolor=white:fontsize=12:x=w-tw-10:y=h-th-10[v2];`,
                    `[0:v]setdar=${dar},scale=${width}:${height}[v0];`,
                    `[v0][1:a][v2][2:a]`,
                    `concat=n=2:v=1:a=1`,
                ].join(''),
                '-vsync', '0',
                outFilename,
            ];

            await ffmpeg.run(...args);
            const data = ffmpeg.FS('readFile', outFilename);

            const blobUrl = URL.createObjectURL(new Blob([data.buffer], {
                type: `video/${OUT_VIDEO_EXTENSION}`,
            }));

            setLoadingData({
                step: 4,
                text: `Complete! Your funny video is ready!`,
            });
            step = 4;
            setVidBlobUrl(blobUrl);
            setVidPercent(100);

            // refVideo.current.setAttribute('src', blobUrl);

            // refVideo.current.play();
        } catch(e) {
            setLoadingData({
                step: -1,
                text: `Error! Apologies, friend. There was a problem while processing your funny video. Here's the non-humanized error message: ${e}`,
            });
        }
    }

    const handleOnPaste = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const {clipboardData} = e;

        window.aaa=clipboardData;
        console.log({
            clipboardData,
            aaa: [
                clipboardData.getData('text/plain'),
                clipboardData.getData('image/png'),
                clipboardData.getData('image/jpeg'),
            ],
        });
        return false;
    };

    const copyToClipboard = async()=>{
        if(!vidBlobUrl) {
            return;
        }
        await navigator.permissions.query({name: "clipboard-write"}).then(async(result) => {
            if (result.state === "granted" || result.state === "prompt") {
                /* write to the clipboard now */
                console.log('writing');

                const blob = await fetch(vidBlobUrl).then(r => r.blob());

                const clipboardItem = new window.ClipboardItem({
                    [blob.type]: vidBlobUrl,
                });

                console.log({blob, clipboardItem})

                await navigator.clipboard.write([clipboardItem]);
                console.log('copied')
            } else {
                console.log('wtf')
            }
        });
    }

    const getColorForStep = (stepId) => {
        switch(stepId) {
            case -1:return 'bg-red-500';
            case 0:return 'bg-gray-300';
            case 1:return 'bg-lime-500';
            case 2:return 'bg-teal-500';
            case 3:return 'bg-indigo-500';
            case 4:return 'bg-indigo-500';
        }
    }

    return (
        <div className="App" onPaste={handleOnPaste}>
            <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline">The <span className="text-indigo-600 xl:inline">April Fools</span> Meme Video Generator</span>
                    </h1>
                </div>
            </main>
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20 text-left">
                <h3 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                    <span className="block">How's it work?</span>
                    <span className="block text-indigo-600">1. Drag and drop a picture you want to use as the bait.</span>
                    <span className="block text-indigo-600">2. You're done! Download your new video that will troll people in discord.</span>
                </h3>
            </div>

            {/*{loadingData ? (*/}
            {/*    <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150">*/}
            {/*        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">*/}
            {/*            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>*/}
            {/*            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />*/}
            {/*        </svg>*/}
            {/*        Building your funny video...*/}
            {/*        {loadingData}*/}
            {/*    </button>*/}
            {/*) : ''}*/}

            <div className="w-[100%] overflow-hidden">
            <div className="grid grid-cols-2 transition-all duration-1000" style={{
                width: vidBlobUrl ? '100%' : '200%',
            }}>

                <div>
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 overflow-hidden">
                        <DragAndDrop
                            onFileUpload={handleOnFileUpload}
                        />
                    </div>

                    <div className="relative pt-1 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="flex mb-2 items-center justify-between">

                            <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full shadow rounded-md text-white transition-all ${getColorForStep(loadingData.step)}`}>
                                {loadingData.step !== 0 && loadingData.step !== 4 ? (
                                    <svg className="animate-spin inline-block -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                    </svg>
                                ) : ''}
                                {loadingData.text} {vidPercent}%
                            </span>
                        </div>
                        <div className={`relative overflow-hidden h-2 mb-4 text-xs flex rounded ${getColorForStep(0)}`}>
                            <div className={`${loadingData.step === 1 ? 'loadingSlowFill': (loadingData.step > 1 ? 'loadingComplete' : 'loadingNone')} loading h-2 rounded absolute shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getColorForStep(1)}`}></div>
                            <div className={`${loadingData.step === 2 ? 'loadingSlowFill': (loadingData.step > 2 ? 'loadingComplete' : 'loadingNone')} loading h-2 rounded absolute shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getColorForStep(2)}`}></div>
                            <div style={{width: `${loadingData.step === 3 ? vidPercent : (loadingData.step > 3 ? 100 : 0)}%`}} className={`loading h-2 rounded absolute shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getColorForStep(3)}`}></div>
                        </div>
                    </div>
                </div>

            {/*<button onClick={()=>{handleUrl('https://youtu.be/e-AuL0GXQRs')}}>button wow</button>*/}
            {/*<div className="flex justify-center items-center w-full">*/}
            {/*    <label htmlFor="dropzone-file"*/}
            {/*           className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">*/}
            {/*        <div className="flex flex-col justify-center items-center pt-5 pb-6">*/}
            {/*            <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none"*/}
            {/*                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
            {/*                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>*/}
            {/*            </svg>*/}
            {/*            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or*/}
            {/*                drag and drop</p>*/}
            {/*            <p className="text-xs text-gray-500 dark:text-gray-400">Video, Photo, Any Media</p>*/}
            {/*        </div>*/}
            {/*        <input id="dropzone-file" type="file" className="hidden"/>*/}
            {/*    </label>*/}
            {/*</div>*/}

                {vidBlobUrl ? (
                    <div className="relative content-center pt-1 py-10 mx-auto max-w-7xl sm:px-6 lg:px-8 bg-zinc-900">
                        <div className="inline-block align-top m-4">
                            <p className={"text-white"}>Your funny video is ready. Post it in all your favorite discords.</p>

                            {/*<button*/}
                            {/*    className="px-4 py-2 font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm"*/}
                            {/*    onClick={copyToClipboard}*/}
                            {/*>*/}
                            {/*    Copy To Clipboard*/}
                            {/*</button>*/}
                            <a
                                className="m-4 block px-4 py-2 font-semibold text-lg bg-cyan-500 text-white rounded-full shadow-sm"
                                href={vidBlobUrl}
                                download={filename}
                            >
                                Download Funny Video
                            </a>
                        </div>
                        <div className="inline-block">
                            <video playsInline controls>
                                <source src={vidBlobUrl} type={`video/${OUT_VIDEO_EXTENSION}`}/>
                            </video>
                        </div>
                    </div>
                ) : ''}

            </div>
            </div>


            {/*<div style={{verticalAlign: 'middle', display: 'inline-block'}}>*/}
            {/*    <img ref={refImg} />*/}
            {/*    <video*/}
            {/*        ref={refVideo}*/}
            {/*        controls*/}
            {/*        style={{outline: '5px solid black'}}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}

export default App;
